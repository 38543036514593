.header {
  &__graphics {
    display: flex;
    gap: 1rem;
    min-height: 210px;
    justify-content: flex-end;

    @media screen and (min-width: 900px) {
      min-height: 260px;
    }
  }

  &__image {
    width: 50%;
    max-width: 200px;
    align-self: flex-end;

    & img {
      width: 100%;
      border-radius: 50%;
    }
  }

  &__headline {
    text-transform: uppercase;
    line-height: 1;
    font-size: 3rem;
    margin-bottom: 0.5rem;
    letter-spacing: 2px;
  }

  &__job-position {
    text-transform: uppercase;
    line-height: 1.3;
    color: var(--primary-color);
    letter-spacing: 2px;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  &__job-description {
    line-height: 1.5;
    margin-bottom: 2rem;
  }

  &__button-group {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
