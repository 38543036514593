.about {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 680px;
  }

  &__text {
    line-height: 1.3;
    font-size: 1.125rem;
    font-weight: 300;
    letter-spacing: 0.15px;
  }

  &__gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 180px 180px;
    flex-wrap: wrap;
    gap: 1rem;

    @media screen and (min-width: 1000px) {
      grid-template-rows: 250px 250px;
    }
    // max-height: 500px;

    & div img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & div:nth-child(2) {
      grid-column: 2 / 3;
      grid-row: 1 / -1;
    }
  }
}

.item {
  min-height: 180px;
  min-width: 250px;
  padding: 1rem;

  & img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
