@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

:root {
  --font-base: 'Roboto', sans-serif;

  --error-background-color: #6b2222;
  --error-text: #ffc7c7;
  --primary-color: #0097fe;
  --secondary-color: #0454a0;
  --grey-background-color: #393c45;
  --dark-background-color: #212430;
  --white-color: #fff;
  --dark-text-color: #1c1f27;
  --black-color: #000;
  --card-background-color: #33394a;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
