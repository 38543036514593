.button {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 400;
  font-size: 1rem;
  font-family: var(--font-base);
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  transition: all 0.3s ease-in-out;
  text-align: center;

  @media screen and (min-width: 900px) {
    padding: 0.75rem 1.5rem;
    font-size: 1.125rem;
  }

  &__primary {
    background-color: var(--primary-color);
    color: var(--dark-text-color);
    font-weight: 500;
  }

  &__secondary {
    background-color: transparent;
    color: var(--white-color);
    border: 1px solid var(--white-color);
  }

  &:hover {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  }
}
