.app {
  background-color: var(--grey-background-color);
  font-family: var(--font-base);
  color: var(--white-color);

  min-height: 100vh;
}

/* Copy/Paste styles */
.app__primarybg {
  background-color: var(--grey-background-color);
}

.app__darkbg {
  background-color: var(--dark-background-color);
}

.app__container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  overflow: hidden;
  flex: 1;
  width: 100%;
  flex-direction: column;
  margin: 6.25rem 1rem;
  gap: 2rem;
  max-width: 1100px;

  @media screen and (min-width: 450px) {
    margin: 9.375rem 2rem;
  }
}

.copyright {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;

  p {
    font-size: 0.875rem;
    color: var(--white-color);
  }
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--white-color);
  text-transform: capitalize;

  span {
    color: var(--primary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--white-color);
  line-height: 1.5;

  @media screen and (min-width: 2000px) {
    font-size: 1.2rem;
  }
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--white-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 0.9rem;
  }
}

.app__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 1rem;

  .app__navigation-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.5rem;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--primary-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

@media screen and (max-width: 500px) {
  .app__navigation,
  .app__social {
    display: none;
  }

  .copyright {
    padding: 2rem;
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}
