.portfolio {
  flex: 1;
  width: 100%;

  &__tags-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 0 2rem;
  }

  &__tags-item {
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--card-background-color);
    color: var(--white-color);
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1rem;
    font-weight: 500;

    &:hover {
      background-color: var(--primary-color);
      color: var(--dark-text-color);
    }

    @media screen and (min-width: 2000px) {
      padding: 0.7rem 1.25rem;
    }

    &--active {
      background-color: var(--primary-color);
      color: var(--dark-text-color);
    }
  }

  &__projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 1rem;

    @media screen and (min-width: 900px) {
      gap: 2rem;
    }
  }

  &__card-item {
    width: 270px;
    flex-direction: column;

    display: flex;
    justify-content: flex-start;

    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--card-background-color);
    color: var(--white-color);

    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
    }

    @media screen and (min-width: 1300px) {
      width: 300px;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
    }
  }

  &__card-image {
    width: 100%;
    height: 230px;

    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
      object-fit: cover;
    }

    @media screen and (min-width: 1300px) {
      height: 250px;
    }

    &--hover {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#212430, 0.7);

      border-radius: 0.5rem;
      opacity: 0;
      transition: all 0.3s ease;

      // FIXME: Make it more mobile friendly
      @media screen and (max-width: 500px) {
        opacity: 1;
      }

      div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;

        margin: 1rem;
        font-family: var(--font-base);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.3s ease;

        svg {
          width: 50%;
          height: 50%;
          color: var(--white-color);
        }
      }
    }
  }

  &__card-content {
    padding: 0.5rem;
    padding-top: 1.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    h4 {
      margin-top: 1rem;
      line-height: 1.5;
      text-align: center;
      font-size: 1.5rem;
    }

    & > p {
      text-align: center;
      font-size: 0.975rem;
    }
  }

  &__card-tag {
    position: absolute;

    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: var(--card-background-color);
    top: -25px;

    p {
      font-size: 1rem;
    }
  }
}
