.skills-and-experience {
  width: 100%;
  margin-top: 3rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: 900px) {
    width: 100%;
    flex-direction: row;
    gap: 1rem;
  }

  &__container {
    width: 100%;
  }
}

.skills {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  margin-right: 5rem;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 1rem;
    transition: all 0.3s ease-in-out;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 90px;
      border-radius: 50%;

      // Fallback color if there is no background color from the api
      background-color: var(--dark-background-color);

      img {
        width: 50%;
        height: 50%;
      }

      &:hover {
        box-shadow: 0 0 25px rgba(#fff, 0.2);
      }
    }

    p {
      font-weight: 500;
      margin-top: 0.5rem;
    }
  }

  &__error {
    align-self: center;
  }

  &__text {
    font-size: 0.8rem;
    text-align: left;
    color: var(--white-color);
    line-height: 1.5;

    @media screen and (min-width: 2000px) {
      font-size: 1.2rem;
    }
  }
}

.experience {
  &__list {
    display: flex;
    flex-direction: column;
    //gap: 2rem;
    background-color: var(--dark-background-color);
    padding: 1.5rem 1rem;
    border-radius: 10px;
    max-width: 550px;
  }

  &__list-item {
    &:not(:last-child) {
      border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }

  &__job-position {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0.25rem;
  }

  &__employer {
    color: var(--primary-color);
    font-weight: 700;
    line-break: strict;
  }

  &__time-period {
    font-size: 12px;
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.75);
  }

  &__description {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
    letter-spacing: 0.15px;
  }

  &__desc-list {
    list-style: none;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
  }

  &__desc-list-item {
    display: grid;
    // flex-direction: row;
    gap: 0.5rem;
    grid-template-columns: 1rem auto;

    svg {
      // width: 5rem;
      color: var(--primary-color);
    }
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    & span {
      background-color: var(--card-background-color);
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      font-size: 14px;
    }
  }

  &__error-box-container {
    align-self: center;
  }
}

// copy/paste
.app__skills {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__skills-exp {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin-top: 2rem;
  }
}

.app__skills-exp-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1rem 0;
}

.app__skills-exp-works {
  flex: 1;

  .app__skills-exp-work {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
    cursor: pointer;

    h4 {
      font-weight: 500;
    }

    p {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }
  }
}

.app__skills-exp-year {
  width: 50px;

  p {
    font-weight: 800;
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    margin-right: 1rem;
    width: 40px;
  }

  @media screen and (min-width: 2000px) {
    width: 120px;
  }
}

.skills-tooltip {
  max-width: 300px;
}

.skills-tooltip {
  max-width: 300px !important;
  background-color: var(--white-color) !important;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px !important;
  padding: 1rem !important;
  color: var(--gray-color) !important;
  text-align: center !important;
  line-height: 1.5 !important;
  opacity: 1 !important;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem !important;
    max-width: 500px !important;
    line-height: 2 !important;
  }
}
