.certificates {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    align-items: flex-start;
  }

  &__card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    max-width: 300px;
    color: inherit;
    text-decoration: none;

    & img {
      width: 100%;
      border-radius: 10px;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.75);
    }
  }
}
