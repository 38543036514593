.contact {
  width: 100%;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  &__card-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 400px;
  }

  &__card {
    background-color: var(--card-background-color);
    color: inherit;
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
    display: flex;
    gap: 1rem;
    align-items: center;
    text-decoration: none;

    & svg {
      font-size: 32px;
      color: var(--primary-color);
    }
  }
}
